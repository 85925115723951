body {
  background-color: #eff3f7 !important;
}

.twitter-picker {
  max-width: 100%;
}

.folderCard::-webkit-scrollbar {
  width: 4px;
}

.folderCard2::-webkit-scrollbar {
  width: 0px;
}

.folderCard3::-webkit-scrollbar {
  width: 3px;
}

.folderCard::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.folderCard::-webkit-scrollbar-thumb {
  background-color: rgb(196, 192, 192);
  outline: 1px solid rgb(124, 148, 172);
}
