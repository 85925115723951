.inputText {
  border: none;
  width: 100%;
  font-size: 16px;
  letter-spacing: 1px;
  background-color: inherit;
  outline: none;
}

.colorCont {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.inputColor {
  margin-right: 4px;
  -webkit-appearance: none;
  border: none;
  width: auto;
  height: auto;
  cursor: pointer;
  background: none;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
    width: 38px;
    height: 22px;
  }
  &::-webkit-color-swatch {
    /* border: 1px solid #bfc9d9; */
    border-radius: 6px;
    padding: 0;
  }
}
